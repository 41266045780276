import './navigation.js';
import './skip-link-focus-fix.js'
import {initMap} from './map.js'
(( $ ) => {


	$(document).ready( () => {
		
		// map init
		$('.location-map').each(function(){
			var map = initMap( $(this) );
		});

		$('li.dropdown').append('<button class="text-h4 open-sub-menu"><i class="px-4 fas fa-angle-down"></i></button>');
		$('li.dropdown').each(function(){
			$(this).children('.sub-menu').insertAfter($(this).siblings('.open-sub-menu'));
			$(this).children('a').hover(function(){
				$(this).parent().children('button').toggleClass('bg-primary');
			});
			$(this).children('button').hover(function(){
				$(this).toggleClass('bg-primary');
				$(this).parent().children('a').toggleClass('bg-primary');
			});
			$(this).children('.open-sub-menu').on('click', function(){
				$(this).children('i').toggleClass('fa-angle-up');
				$(this).parent('li').children('.sub-menu').slideToggle();
				$(this).parent('li').toggleClass('active');
			});
		});
	});


	/* optional triggers

	$(window).load(() => {

	});

	$(window).resize(() => {

	});

	*/


} )( jQuery );
